section#home-6-boxes .flex-container .box .photo {
    background-position: center center;
}

@media screen and (max-width: 767px) {
    section#home-hero .hero-desktop {
        display: none;
    }
}
@media screen and (min-width: 768px) {
    section#home-hero {
        min-height: 100vh;
    }
    /**
    Don't centre the video, they don't want it cropped
    **/
    section#home-hero .hero-desktop .video-bg video {
        position: relative;
        width: 100%;
        left: 0;
    }
}
@media screen and (max-width: 1024px) {
    .navbar .navbar-brand img {
        height: 38px;
    }
}

.algolia-places {
    /* Algolia's default width is 100%. Our v2 search bar styling really doesn't like that */
    width: auto;
}

.tether-element.ap-dropdown-menu {
    /* By popping the dropdown to DOM root via Tether, the dropdown becomes SUPER WIDE */
    max-width: 30em;
}
